import { render, staticRenderFns } from "./SkupApproval.vue?vue&type=template&id=add16c22&"
import script from "./SkupApproval.vue?vue&type=script&lang=js&"
export * from "./SkupApproval.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports